<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div :class="{'modal__context': modalView}">
                <section class="item-types">
                    <breadcrumbs v-if="!modalView"></breadcrumbs>
                    <h2 class="titlePage_subtitle titlePage">SELECT INSERT COLOUR</h2>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <form action="" class="form" v-on:submit.prevent>
                                <label class="form__label">
                                    <input type="text" class="form__input"
                                           placeholder="Type to Search"
                                           name="search color"
                                           v-model="colourInput"
                                    >
                                </label>
                                <button type="button" class="btn_link">
                                    <svg-icon name="search"/>
                                </button>
                            </form>
                        </div>
                    </div>
                </section>

                <section class="item-types">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                             v-for="colour in defaultColours"
                             :key="colour.id"
                             @click="selectDefaultColor(colour)"
                        >
                            <!--<div class="box">-->
                                <!--<img class="box__img" :src="colour.image" alt="color.colour_name" v-if="colour.image">-->
                                <div class="colours colour_box">
                                <v-lazy-image-plugin :src="color.image" v-if="color.image"/>
                                <p v-else-if="colour.hexcode">
                                    <svg-icon name="palette" class="large_icon box__img" :style="{color: colour.hexcode}"/>
                                </p>
                                <p v-if="!colour.image && !colour.hexcode">
                                    <svg-icon name="palette" class="large_icon box__img"/>
                                </p>
                                </div>
                                <p v-if="colour.is_same_as_frame" class="text_default">
                                    <b class="text_red">Same as Frame</b><br/>({{ colour.colour_name }})
                                </p>
                                <p v-else class="text_default">{{ colour.colour_name }}</p>
                            <!--</div>-->
                        </div>
                    </div>
                </section>

                <section>
                    <div class="divider">
                        <p class="divider__text text_green">OR SELECT BELOW</p>
                    </div>
                </section>

                <section class="item-types">
                    <div class="row"
                         v-for="(item, key, index) in colours"
                    >
                        <div class="col-12">
                            <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                             v-for="colour in item.colours"
                             :key="colour.id"
                             @click="selectColor(colour, item)"
                        >
                            <div class="box">
                                <div class="colours colour_box colours_small">
                                <img class="box__img" :src="colour.image" alt="color.colour_name" v-if="colour.image">
                                <p v-else-if="colour.hexcode">
                                    <svg-icon name="palette" class="large_icon box__img" :style="{color: colour.hexcode}"/>
                                </p>
                                <p v-if="!colour.image && !colour.hexcode">
                                    <svg-icon name="palette" class="large_icon box__img"/>
                                </p>
                                </div>
                                <p class="text_default">{{colour.colour_name}}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="modal modal_wrap" v-if="warning" tabindex="1">
            <div class="modal_popup">
                <p class="text text_white">Chosen colour requires additional price. Please, click "I Accept" button if
                    you want to continue.</p>
                <div class="justify-content-between">
                    <button class="btn btn_transparent" @click="warning = !warning"><svg-icon name="solid/ste-backward" /> Back</button>
                    <button class="btn btn_transparent" @click="accept"><svg-icon name="solid/check" /> I Accept</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import breadcrumbs from './../../parts/breadcrumbs';
  import {mapState, mapMutations, mapActions} from 'vuex';
  import {searchColors, simpleSearch} from '../../../helpers/search';
  import VLazyImagePlugin from "v-lazy-image";

  export default {
    name: "InsertColour",
    components: {breadcrumbs, VLazyImagePlugin},
    data() {
      return {
        colourInput: '',
        warning: false,
        currentSelected: {}
      }
    },
    methods: {
      ...mapMutations('options', ['setTypeOrigin', 'setType', 'setSelected', 'modalView']),
      ...mapMutations('app', ['pushBreadcrumb']),
      ...mapActions('options', ['getInsertColours']),
      accept() {
        this.warning = !this.warning;
        this.select(this.currentSelected)
      },
      selectDefaultColor(colour) {
        if (colour.is_show_price_warning) {
          this.warning = !this.warning;
        } else {
          this.select(colour)
        }
      },
      selectColor(colour, group) {
        if (group.is_show_price_warning) {
          this.warning = !this.warning;
          this.currentSelected = colour
        } else {
          this.select(colour)
        }
      },
      select(colour) {
        let lastBreadcrumb = this.data.breadcrumbs[this.data.breadcrumbs.length - 1];
        this.setSelected(lastBreadcrumb.optionData);

        this.$store.dispatch('options/getOptionsNew');

        lastBreadcrumb.optionData.colour = {
          is_same_as_frame: colour.is_same_as_frame,
          id: colour.id,
          name: colour.name
        };
        this.pushBreadcrumb({
          route: lastBreadcrumb.route,
          name: colour.colour_name,
          option: true,
          optionData: lastBreadcrumb.optionData.colour,
          optionName: 'INSCOLOUR',
        });
      }
    },
    computed: {
      ...mapState('options', ['name', 'price', 'modalView', 'insertColoursDefault', 'insertColours']),
      ...mapState('app', ['data']),
      colours() {
        return searchColors(this.colourInput, this.insertColours)
      },
      defaultColours() {
        return simpleSearch(this.colourInput, this.insertColoursDefault, 'colour_name')
      }
    },
    created() {
      this.getInsertColours()
    }
  }
</script>

<style scoped lang="scss">
    @import "../../../../src/assets/scss/utils/vars";

    .box {
        margin-bottom: 1rem;
        &__img {
            /*position: static;*/
            /*display: block;*/
            /*margin: 0 auto;*/
            /*margin-bottom: 20px;*/
            /*width: 100%;*/
            /*max-width: 160px;*/
            /*height: 100px;*/
        }
        p {
            text-align: center;
        }
    }

    .form {
        position: relative;
        .btn_link {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            svg {
                color: $green;
            }
        }

        &__input {
            padding-right: 40px;
            border: 2px solid #0b3553;
        }

    }

    .justify-content-between {
        display: flex;
    }
</style>
